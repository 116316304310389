.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flag-dropdown {
  background-color: #172554 !important;
  .selected-flag {
    background-color: #283970 !important;
  }
  &:hover {
    background-color: #1b2c63 !important;
  }
  .country-list {
    background-color: #172554 !important;
    .country {
      &:hover {
        background-color: #1b2c63 !important;
      }
      &.highlight {
        background-color: #23387d !important;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.otp-field {
  width: 2.5rem !important;
  height: 3rem;
  font-size: 1.2rem;
  &::-moz-selection {
    background: transparent;
  }
  &::selection {
    background: transparent;
  }
}

.rdrYearPicker,
.rdrMonthPicker {
  select,
  option {
    background-color: #0f172a;
    color: white;
    &:focus {
      background-color: #0f172a;
      color: white;
    }
  }
}

.custom-date-range {
  width: 100%;
  background-color: #1e293b;
  color: white;
}

.rdrCalendarWrapper,
.rdrDateDisplayWrapper {
  background-color: #1e293b;
  color: white;
}

.rdrDay {
  background-color: #1e293b;
  border-color: #334155;
}

.rdrDayNumber span,
.rdrMonthAndYearPickers select {
  color: white;
}

.rdrDateDisplayWrapper {
  display: none;
}
